.container {
    position: relative;
}

.page {
    position: absolute;
    left: 15px;
    right: 15px;
}

.page-enter {
    opacity: 0;
    transform: scale(1.1);
}

.page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    opacity: 1;
    transform: scale(1);
}

.page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

/* switch transition */

.buttonlabel {
    margin-bottom: 0.5rem;
  }
  
  .modes {
    margin-bottom: 1rem;
  }-container {
    margin-bottom: 0.5rem;
  }
  
  .fade-enter .button-container {
    opacity: 0;
    transform: translateX(-100%);
  }
  .fade-enter-active .button-container {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit .button-container {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit-active .button-container {
    opacity: 0;
    transform: translateX(100%);
    
  }
  .fade-enter-active .button-container,
  .fade-exit-active .button-container {
    transition: opacity 5000ms, transform 5000ms;
  }
  
  /* transition group */

  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }