.logo-image { margin: 5px; padding: 5px; }
.navbar {
    box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.15)
  }
  .navbar .navbar-brand{
    padding: 5px;
  }

  #basic-nav-dropdown {
    padding: 0 1rem;
    color: #686868;
  }

