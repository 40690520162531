.radio-toolbar {
  margin: 10px;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  background-color: #ddd;
  padding: 10px 20px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border: 2px solid #444;
  border-radius: 4px;
}

.radio-toolbar label:hover {
  background-color: #dfd;
}

.radio-toolbar input[type="radio"]:focus + label {
  border: 2px dashed #444;
}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: #bfb;
  border-color: #4c4;
}
.value-highlight {
  font-weight: 900;
  font-size: 150%;
}

.total-value-highlight {
  font-weight: 600;
  font-size: 100%;
}

.card-application-white {
  /* background-color: #FF8F5E; */
  background-color: #ffff;
  border-radius: 8px;
  color: #fff;
  max-width: 550px;
  margin: 20px 0 70px;
  min-height: 400px;
  padding: 30px;
}

.plain-form {
  /* background-color: #f6f6f6; */
  padding: 0 2% 0 2%;
  margin-top: 25px;
  margin-bottom: 30px;
  border-radius: 8px;
}

.margin-10 {
  margin-top: 15px;
}

.form-subheading {
  margin-top: 0px;
  padding-bottom: 10px;
  border-bottom: #d5d5d5 solid 1px;
  margin-bottom: 10px;
}
/* .light-border{
    border-style: solid;
    border-color: #777676;
    border-width: 0.25px;
  } */

.block-div {
  background-color: #f8f8f8;
  padding: 10px 20px 20px 20px;
  margin-top: 10px;
}
.block-div .lead {
  color: #353535;
  font-size: 120%;
  font-weight: 600;
  margin-bottom: 0px;
}

.block-div .lead-total {
  color: #353535;
  font-size: 200%;
  font-weight: 600;
  margin-bottom: 0px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .block-div .lead-total {
    color: #353535;
    font-size: 200%;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: center;
  }
  .block-div .lead-total-number {
    color: #353535;
    font-size: 200%;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: center;
  }
  .space-on-small {
    padding-top: 50px;

  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 768px) {
  .block-div .lead-total {
    color: #353535;
    font-size: 200%;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: left;
  }
  .block-div .lead-total-number {
    color: #353535;
    font-size: 200%;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: right;
  }
}

.form-control {
  padding-right: 10px !important;
}