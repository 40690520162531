/* .plain-form {
    background-color: #f6f6f6;
    padding: 0 2% 0 2%;
    margin-top: 25px;
    margin-bottom: 30px;
    border-radius: 8px;
    padding-top: 25px;
    padding-bottom: 30px;
    } */


.card-application-white {
    /* background-color: #FF8F5E; */
    background-color: #FFFF;
    border-radius: 8px;
    color: #fff;
    max-width: 550px;
    margin: 20px 0 70px;
    min-height: 400px;
    padding: 30px;
  }
  
  .plain-form {
    /* background-color: #f6f6f6; */
    padding: 0 2% 0 2%;
    margin-top: 25px;
    margin-bottom: 30px;
    border-radius: 8px;
    
    }
  
    .margin-10{
      margin-top: 15px;
    }
  
    .form-subheading{
    margin-top: 0px;
      padding-bottom: 10px;
      border-bottom: #d5d5d5 solid 1px;
      margin-bottom: 10px;
    }
    /* .light-border{
      border-style: solid;
      border-color: #777676;
      border-width: 0.25px;
    } */
    