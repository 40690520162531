.footer .links ul a:not(.btn) {
    color: #54595F;
    font-weight: 600;
}

.footer .links ul a:not(.btn):hover {
    color: #ff0000;
    font-weight: 600;
}

.filter-green {
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.social-area {
    padding: 15px 10px;
}

.social-area a {
    padding: 5px;
}

.links a {
    display: inline-block;
      text-decoration: none;
}

.links-last-row {
    font-size: small;
}
.links-last-row a {
    display: inline-block;
    text-decoration: none;
}

.uppercase-links {
    text-transform: uppercase;
  
}

.stacked-links {
    margin-top: 10px;
}

.stacked-links li {
    display: block;
    line-height: 26px;
    text-decoration: none;
}

.stacked-links h4 {
    margin-top: 0px;
}

.footer .links {
    display: inline-block;
}

