/* .section{

    background-image: url("./../assets/images/background/auto-mart-header-bg-1680x900.jpg");
    background-repeat: no-repeat;
} */

.first-level{
    padding: 80px 0 0 0;
}


.promotion-image{
    /* background-image: url("./../assets/images/background/auto-zone-header-bg-2000x900-alt1.jpg"); */
   background-image: url("../assets/images/homepage/website-background-alt1-1200x900.jpg") ;
             /* opacity: 0.75; */
             /* filter: sepia(100%) hue-rotate(200deg) saturate(100%); */
 
   /* Create the parallax scrolling effect */
   background-attachment: fixed;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
 }

 .promotion-box{
  background-color: #f6f6f6;
}

 .blue-title {
     color:  #003468;
     font-size: 300%;
 }

 .red-sub-title {
     color: #BF1E2E;
     font-size: 200%;
     font-weight: 550;
 }

 .div-with-grey-border{
    padding: 15px;
    color: #000000;
    /* color: #ffffff; */
    min-height: 130px;
    /* opacity: 0.7; */
    border-style: solid;
    border-color: #777676;
    border-width: 0.5px;
    
  }
  
  
  .div-with-grey-border:hover {
    background-color: #f6f6f6;
    transition: 1s;
    /* opacity: 0.5; */
    color: #000000;
  }

  .div-with-grey-border .name{
    color:  #BF1E2E;
    font-size: 150%;
    font-weight: 600;
  }

  .div-with-light-grey-border{
    padding: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #000000;
    /* color: #ffffff; */
    min-height: 130px;
    background-color: #f6f6f6;
    /* opacity: 0.7; */
    /* border-style: solid; */
    /* border-color: #777676; */
    /* border-width: 0.05px; */
    
  }

  .div-with-light-grey-border .customer-name{
    color:  #BF1E2E;
    font-size: 100%;
    font-weight: 500;
  }

  .div-with-light-grey-border .description{
    color:  #003468;
    /* font-weight: 600; */
    font-size: 100%;
  }

/* description */
  .div-with-grey-border .email{
    font-size: 100%;
  }

  .div-with-grey-border .desig{
    color:  #003468;
    font-weight: 600;
    font-size: 150%;
  }




  .card-application-white {
    /* background-color: #FF8F5E; */
    background-color: #FFFF;
    border-radius: 8px;
    color: #fff;
    max-width: 550px;
    margin: 20px 0 70px;
    min-height: 400px;
    padding: 30px;
  }
  
  .plain-form {
    /* background-color: #f6f6f6; */
    padding: 0 2% 0 2%;
    margin-top: 25px;
    margin-bottom: 30px;
    border-radius: 8px;
    
    }
  
    .margin-10{
      margin-top: 15px;
    }
  
    .form-subheading{
    margin-top: 0px;
      padding-bottom: 10px;
      border-bottom: #d5d5d5 solid 1px;
      margin-bottom: 10px;
    }
    /* .light-border{
      border-style: solid;
      border-color: #777676;
      border-width: 0.25px;
    } */
    
    .gold-fa .fa{
      color: gold;
    }