.section-listing {
    padding: 30px 0; }

/* .top-margin-50 {
margin-top: 90px;
} */

.wrapper{
    /* background-image: url("./../assets/images/background/auto-zone-header-bg-2000x900-alt1.jpg"); */
    background-repeat: no-repeat;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    /* .top-margin {
      margin-top: 90px;
    } */
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    /* .top-margin {
      margin-top: 90px;
    } */
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    /* .top-margin {
      margin-top: 90px;
    } */
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    /* .top-margin {
      margin-top: 90px;
    } */
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    /* .top-margin {
      margin-top: 90px;
    } */
  }
  
/* relevant styles */
.img__wrap {
  position: relative;
  height: 200px;
  width: 257px;
}

.img__description_layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(36, 62, 206, 0.6);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}

.img__wrap:hover .img__description_layer {
  visibility: visible;
  opacity: 1;
}

.img__description {
  transition: .2s;
  transform: translateY(1em);
}

.img__wrap:hover .img__description {
  transform: translateY(0);
}


div.sticky {
  position: -webkit-sticky;
  position: fixed;
  right: 3em;
  bottom: 5em;
  /* top: 0; */
  background-color:transparent;
  width: 80px; 
  height: 80px; 
  /* padding: 10px 16px;  */
  line-height: 13px;
  border-radius: 40px; 
  font-size: 12px; 
  text-align: center; 
  z-index: 100;
}

table td {
  color: #54595F;
  font-weight: 500;
}

.btn-circle.btn-sm { 
  width: 30px; 
  height: 30px; 
  padding: 6px 0px; 
  border-radius: 15px; 
  font-size: 8px; 
  text-align: center; 
} 
.btn-circle { 
  width: 50px; 
  height: 50px; 
  padding: 7px 10px; 
  border-radius: 25px; 
  font-size: 10px; 
  text-align: center; 
} 
.btn-circle-x { 
  width: 50px; 
  height: 50px; 
  padding: 10px 15px; 
  line-height: 13px;
  border-radius: 25px; 
  font-size: 12px; 
  text-align: center; 
} 



/* .list-price{
  display: inline-block;
  font-size: 110%;
  font-weight: 600;
  color: #54595F;
  height: 20px;
} */


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .list-price{
  
  font-size: 150%;
  font-weight: 600;
  color: #54595F;
  height: 20px;
}
.kilometers{
  display: inline-block;
  font-size: 100%;
  font-weight: 500;
  color: #54595F;
  height: 15px;
}
.year-make{
  font-size: 100%;
  font-weight: 500;
  color: #bf1e2e;
}

.model-trim{
  font-size: 150%;
  font-weight: 600;
  color: #bf1e2e;
}


.model-trim-desc{
  display: inline-block;
  font-size: 100%;
  font-weight: 600;
  color: #000000;
  /* height: 55px; */
}

.product-description {
  display: inline-block;
  color: #000000;
  font-size: 100%;
  font-weight: 600;
  height: 55px;
}

.forced-product-description{
  height: 55px;
}

  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .list-price{
      display: inline-block;
      font-size: 110%;
      font-weight: 600;
      color: #54595F;
      height: 17px;
    }
    .kilometers{
      display: inline-block;
      font-size: 100%;
      font-weight: 500;
      color: #54595F;
      height: 15px;
    }
    .year-make{
      font-size: 100%;
      font-weight: 500;
      color: #bf1e2e;
    }
    
    .model-trim{
      font-size: 150%;
      font-weight: 600;
      color: #bf1e2e;
    }

    .model-trim-desc{
      display: inline-block;
      font-size: 100%;
      font-weight: 600;
      color: #000000;
      /* height: 55px; */
    }

    .product-description {
      display: inline-block;
      color: #000000;
      font-size: 100%;
      font-weight: 600;
      /* height: 55px; */
    }


  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .list-price{
      display: inline-block;
      font-size: 110%;
      font-weight: 600;
      color: #54595F;
      height: 17px;
    }
    .kilometers{
      display: inline-block;
      font-size: 80%;
      font-weight: 500;
      color: #54595F;
      height: 15px;
    }
    .year-make{
      font-size: 90%;
      font-weight: 500;
      color: #bf1e2e;
    }
    
    .model-trim{
      font-size: 120%;
      font-weight: 600;
      color: #bf1e2e;
    }

    .model-trim-desc{
      display: inline-block;
      font-size: 100%;
      font-weight: 600;
      color: #000000;
      height: 55px;
    }

    .product-description {
      display: inline-block;
      color: #000000;
      font-size: 100%;
      font-weight: 600;
      height: 55px;
    }

  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .list-price{
      display: inline-block;
      font-size: 110%;
      font-weight: 600;
      color: #54595F;
      height: 17px;
    }
    .kilometers{
      display: inline-block;
      font-size: 80%;
      font-weight: 500;
      color: #54595F;
      height: 15px;
    }
    .year-make{
      font-size: 90%;
      font-weight: 500;
      color: #bf1e2e;
    }
    
    .model-trim{
      font-size: 120%;
      font-weight: 600;
      color: #bf1e2e;
    }
    .model-trim-desc{
      display: inline-block;
      font-size: 95%;
      font-weight: 600;
      color: #000000;
      height: 65px;
    }

    .product-description {
      display: inline-block;
      color: #000000;
      font-size: 95%;
      font-weight: 600;
      height: 65px;
    }

  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .list-price{
      display: inline-block;
      font-size: 110%;
      font-weight: 600;
      color: #54595F;
      height: 17px;
    }
    .kilometers{
      display: inline-block;
      font-size: 80%;
      font-weight: 500;
      color: #54595F;
      height: 15px;
    }
    .year-make{
      font-size: 90%;
      font-weight: 500;
      color: #bf1e2e;
    }
    
    .model-trim{
      font-size: 120%;
      font-weight: 600;
      color: #bf1e2e;
    }

    .model-trim-desc{
      display: inline-block;
      font-size: 95%;
      font-weight: 600;
      color: #000000;
      height: 65px;
    }

    .product-description {
      display: inline-block;
      color: #000000;
      font-size: 95%;
      font-weight: 600;
      height: 65px;
    }

  }



.card-product{
  border: #dededd;
  border-style: solid;
  border-width: 1px;
}


.location-offsite {
  background-color: #003468;
  /* padding: 0 5px 0px 5px; */
  color: #fff;
  font-size: 80%;
  font-weight: 600;
  text-transform: uppercase;
}

.location-onsite {
  background-color: #bf1e2e;
  /* padding: 0 5px 0px 5px; */
  color: #fff;
  font-size: 80%;
  font-weight: 600;
  text-transform: uppercase;
}

.inventory-card {
  margin-top: 5px;
  min-height: 400px;
}

.inventory-card:hover {
  background-color: #F6F6F6;
}

.inventory-card .card-body {
  padding-top: 5px;
  padding-bottom: 5px;
}
.disclaimer-box{
  border: #dededd;
  border-style: solid;
  border-width: 1px;
  background-color: #f6f6f6;
  padding: 5px;

  
}

.vehicle-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;

  background: #ffffffce; /* Black see-through */
  color: #bf1e2e;

  transition: .5s ease;
  color: bf1e2e;
  font-size: 200%;
  font-weight: 600;
  padding: 10px;
  text-align: center;
  opacity: 1;
}

.vehicle-overlay-red {
  position: absolute;
  bottom: 200px;
  background: rgba(235, 8, 8, 0.7); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 200%;
  font-weight: 600;
  padding: 10px;
  text-align: center;
  opacity: 1;
}

.vehicle-overlay-white {
  position: absolute;
  bottom: 200px;

  background: #f6f6f6ce; /* Black see-through */
  color: #bf1e2e;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: bf1e2e;
  font-size: 200%;
  font-weight: 600;
  padding: 10px;
  text-align: center;
  opacity: 1;
}

